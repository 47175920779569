import React, { forwardRef, useEffect, useState } from 'react';

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Loader from "components/Loader/Loader";

import { Redirect } from 'react-router-dom';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Referidos(){
    const [color, setColor] = useState("");
    const [message, setMessage] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [load, setLoad] = useState(false);

    const [Nombres, setNombres] = useState("");
    const [ApellidoPaterno, setApellidoPaterno] = useState("");
    const [ApellidoMaterno, setApellidoMaterno] = useState("");
    const [Celular, setCelular] = useState("");

    const sendSnackBar = (pmessage, pcolor) => {
        setOpenSnackbar(true);
        setColor(pcolor);
        setMessage(pmessage);
    };

    const SnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSnackbar(false);
    };

    const Login = async () => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify({UserName: process.env.REACT_APP_USER, Password: process.env.REACT_APP_PASS}),
                headers: {
                    "Content-Type": "application/json"
                }
            };

            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Login",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const InsertaReferidos = async (data, token) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };
    
            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Referidos/InsertaReferidos",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const getUser = () => {
        return JSON.parse(localStorage.getItem("userICR")).IdUsuario;
    };

    const ValidaDatos = () => {
        if(Nombres !== "" && ApellidoPaterno !== "" && ApellidoMaterno !== "" && Celular !== ""){
                return { Nombres, ApellidoPaterno, ApellidoMaterno, Celular, Capturista: getUser() };
        } else {
            return { error: true }
        }
    }

    const EnviaDatos = () => {
        setLoad(true);
        
        let datos = ValidaDatos();
        
        if(datos.error === undefined){
            Login()
            .then(result => {
                if(result !== undefined){
                    InsertaReferidos(datos, result.token)
                    .then(result => {
                        if(result.success){
                            sendSnackBar("Registro exitoso", "success");
                            setTimeout(function(){ window.location.reload(false); }, 1000);
                        } else {
                            sendSnackBar("Error de conexión", "error");
                        }
        
                        setLoad(false);
                    }).catch(error => sendSnackBar("Error de conexión", "error"));
                } else {
                    sendSnackBar("Error de conexión", "error");
                    setLoad(false);
                }
            }).catch(error => sendSnackBar("Error de conexión", "error"));
        } else {
            sendSnackBar("Todos los valores son obligatorios", "error");
            setLoad(false);
        }
    };

    const GetIdUsuario = () => {
        return JSON.parse(localStorage.getItem('userICR')).IdUsuario;
    };
    
    const UsuarioActivo = async (data, token) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };

            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Usuario/UsuarioActivo",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }

        return dataResponse;
    };

    useEffect(() => {
        Login()
        .then(result => {
          let token = result.token;
    
          if(result !== undefined) {
            UsuarioActivo({ IdUsuario: GetIdUsuario() },token)
            .then(result => {
              console.log(result);
              if(result.activo === false){
                localStorage.removeItem("userICR");
                return <Redirect to='/admin'/>;
              }
            });
          } else {
            sendSnackBar("Error de conexión", "error");
          }
        });
      }, []);

    return (
        <>
            <Card style={{ marginBottom: "30px" }}>
                <Grid container spacing={3} style={{ padding: "30px" }}>
                    <Grid item xs={12} sm={12} xl={12}>
                        <Typography variant="h6">
                            Datos personales
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField onChange={(event) => setNombres(event.target.value)} value={Nombres} label="Nombre" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 100 }} />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField onChange={(event) => setApellidoPaterno(event.target.value)} value={ApellidoPaterno} label="Apellido Paterno" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 100 }} />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField onChange={(event) => setApellidoMaterno(event.target.value)} value={ApellidoMaterno} label="Apellido Materno" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 100 }} />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField onChange={(event) => setCelular(event.target.value)} value={Celular} label="Celular" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 10 }} /> 
                    </Grid>
                </Grid>
            </Card>
            <Grid container
                direction="row-reverse"
                justifyContent="flex-start"
                alignItems="center">
                <Grid item xs={12} sm={12} xl={3}>
                    <Button
                        color="info"
                        variant="contained"
                        onClick={EnviaDatos}
                        style={{ float: "right" }}
                        size="large"
                        >
                            Enviar
                    </Button>
                </Grid>
            </Grid>
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={openSnackbar} onClose={SnackbarClose} autoHideDuration={6000}>
                <Alert onClose={SnackbarClose} severity={color} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            <Loader load={load} />
        </>
    );
}

export default Referidos;