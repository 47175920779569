import React, { useState, useEffect, forwardRef } from "react";
import CSVReader from 'react-csv-reader'
import MaterialTable from "material-table";

import Grid from "@material-ui/core/Grid";
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Loader from "components/Loader/Loader.jsx";

import moment from "moment";
import "moment/locale/es";

import "assets/css/cargaSolicitudes.css";

import { Redirect } from 'react-router-dom';

moment.locale("es");

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CargaMasiva(props){
    const [columnCarga] = useState([
        { title: "Nombres", field: "Nombres", type: "string" },
        { title: "Apellido Paterno", field: "ApellidoPaterno", type: "string" },
        { title: "Apellido Materno", field: "ApellidoMaterno", type: "string" },
        { title: "Celular", field: "Celular", type: "string" },
        { title: "Referencia Invex", field: "ReferenciaInvex", type: "string" },
        { title: "Tipo Base", field: "TipoBase", type: "string" },
        { title: "Fecha Higienizacion", field: "FechaHigienizacion", type: "string" }
    ]);

    const headers = {
        Nombres: "Nombres",
        ApellidoPaterno: "ApellidoPaterno",
        ApellidoMaterno: "ApellidoMaterno",
        Celular: "Celular",
        ReferenciaInvex: "ReferenciaInvex",
        TipoBase: "TipoBase",
        FechaHigienizacion: "FechaHigienizacion"
    };

    const [dataCarga, setDataCarga] = useState([]);

    const [color, setColor] = useState("");
    const [message, setMessage] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState("");

    const [load, setLoad] = useState(false);

    const validateDate = (date) => {
        let dateArray = date.split("/");
        let validDate = "";

        if(dateArray[0].length === 4){
            validDate = moment(date,'YYYY-MM-DD').format('YYYY-MM-DD');
        } else {
            let newDate = dateArray[2] + "/" + dateArray[1] + "/" + dateArray[0];
            validDate = moment(newDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
        }
    
        return moment(validDate, 'YYYY-MM-DD').isValid() ? validDate : "";
    };

    const convertToCSV = (objArray) => {
        const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
        let str = "";

        for (let i = 0; i < array.length; i++) {
            let line = "";

            for (let index in array[i]) {
                if (line !== "") line += ",";
                line += array[i][index];
            }

            str += line + "\r\n";
        }

        return str;
    };

    const exportCSVFile = (headers, items, fileName) => {
        if (headers) {
            items.unshift(headers);
        }

        const jsonObject = JSON.stringify(items);
        const csv = convertToCSV(jsonObject);
        const exportName = fileName + ".csv" || "export.csv";
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, exportName);
        } else {
            const link = document.createElement("a");
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportName);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };

    const SnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSnackbar(false);
    };

    const handleLoaded = (data, fileInfo) => {
        let error = [];
        let headers = { no_columna: "No columna", error: "Error" };

        let valores = data.map((item, index) => {
            let sanitizacion;
            let columna = index + 2;
            
            let { nombres, apellidopaterno, apellidomaterno, celular, referenciainvex, fechahigienizacion, tipobase } = item;

            try{
                sanitizacion = fechahigienizacion === null ? null : validateDate(fechahigienizacion);
        
                if(nombres !== null){
                    if(nombres.length > 100){
                        error.push({ no_columna: columna, error: "El nombre debe tener menos de 100 caracteres" });
                    }
                } else {
                    error.push({ no_columna: columna, error: "El nombre es obligatorio" });
                }
    
                if(apellidopaterno !== null){
                    if(apellidopaterno.length > 100){
                        error.push({ no_columna: columna, error: "El apellido paterno debe tener menos de 100 caracteres" });
                    }
                } else {
                    error.push({ no_columna: columna, error: "El apellido paterno es obligatorio" });
                }
    
                if(apellidomaterno !== null){
                    if(apellidomaterno.length > 100){
                        error.push({ no_columna: columna, error: "El apellido materno debe tener menos de 100 caracteres" });
                    }
                } else {
                    error.push({ no_columna: columna, error: "El apellido materno es obligatorio" });
                }
    
                if(celular !== null) {
                    if(celular.length > 10){
                        error.push({ no_columna: columna, error: "El celular debe tener menos de 10 caracteres" });
                    }
                }  else {
                    error.push({ no_columna: columna, error: "El celular es obligatorio" });
                }
    
                if(referenciainvex !== null) {
                    if(referenciainvex.length > 100){
                        error.push({ no_columna: columna, error: "La referencia invex debe tener menos de 100 caracteres" });
                    }
                }  else {
                    error.push({ no_columna: columna, error: "La referencia invex es obligatoria" });
                }
    
                if(sanitizacion !== null){
                    if(sanitizacion === ""){
                        error.push({ no_columna: columna, error: "La fecha de sanitizacion debe tener el formato aaaa/mm/dd" });
                    }
                } else {
                    error.push({ no_columna: columna, error: "La fecha de sanitizacion es obligatoria" });
                }

                if(tipobase !== null) {
                    if(tipobase.length > 100){
                        error.push({ no_columna: columna, error: "El tipo de base debe tener menos de 100 caracteres" });
                    }
                }  else {
                    error.push({ no_columna: columna, error: "El tipo de base es obligatoria" });
                }
            } catch(err) {
                error.push({ no_columna: columna, error: err.message });
            }

            return { 
                Nombres: String(nombres), 
                ApellidoPaterno: String(apellidopaterno), 
                ApellidoMaterno: String(apellidomaterno), 
                Celular: String(celular), 
                ReferenciaInvex: String(referenciainvex),
                FechaHigienizacion: sanitizacion,
                TipoBase: String(tipobase)
            };
        });

        if(error.length > 0){
            sendSnackBar("El archivo de carga contiene algunos errores.", "error");
            exportCSVFile(headers, error, "Errores");
        } else {
            setDataCarga(valores);
        }
    };

    const InsertaCapturas = async (data, token) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };
    
            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Captura/InsertaCapturas",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const handleCarga = (e) => {
        e.preventDefault();

        if(dataCarga.length > 0){
            setLoad(true);

            Login()
            .then(result => {
                if (result !== undefined) {
                    InsertaCapturas(dataCarga, result.token)
                    .then(result => {
                        sendSnackBar("Carga exitosa.", "success");
                        setLoad(false);
                    }).catch(error => sendSnackBar("Error de conexión", "error"));
                } else {
                    sendSnackBar("Error interno, comunícate con soporte.", "error");
                    setLoad(false);
                }
            }).catch(error => sendSnackBar("Error de conexión", "error"));
        } else {
            sendSnackBar("Debes elegir un archivo con al menos un registro.", "error");
        }
    };

    const Login = async () => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify({UserName: process.env.REACT_APP_USER, Password: process.env.REACT_APP_PASS}),
                headers: {
                    "Content-Type": "application/json"
                }
            };

            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Login",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const sendSnackBar = (pmessage, pcolor) => {
        setOpenSnackbar(true);
        setColor(pcolor);
        setMessage(pmessage);
    };

    const papaparseOptions = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: header => header.toLowerCase().replace(/\W/g, '_')
    };

    const GetIdUsuario = () => {
        return JSON.parse(localStorage.getItem('userICR')).IdUsuario;
    };
    
    const UsuarioActivo = async (data, token) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };

            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Usuario/UsuarioActivo",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }

        return dataResponse;
    };

    useEffect(() => {
        Login()
        .then(result => {
          let token = result.token;
    
          if(result !== undefined) {
            UsuarioActivo({ IdUsuario: GetIdUsuario() },token)
            .then(result => {
              console.log(result);
              if(result.activo === false){
                localStorage.removeItem("userICR");
                return <Redirect to='/admin'/>;
              }
            });
          } else {
            sendSnackBar("Error de conexión", "error");
          }
        });
      }, []);

    return (
        <div className="container">
            <Grid container>
                <GridItem xs={12} sm={12} md={6}>
                    <CSVReader
                        cssClass="csv-reader-input"
                        label="Selecciona el archivo CSV a cargar."
                        onFileLoaded={handleLoaded}
                        onError={(error) => sendSnackBar("Error al cargar el archivo", "error")}
                        parserOptions={papaparseOptions}
                        inputId="reader"
                        fileEncoding="ISO-8859-1"
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    <Grid container>
                        <GridItem xs={12} sm={12} md={6}>
                            <Button
                                style={{ marginTop: "40px" }}
                                onClick={e => {
                                    exportCSVFile(headers, [], 'Layout_Carga');
                                }}
                                color="info"
                            >
                                Descargar Layout
                            </Button>
                        </GridItem>
                    </Grid>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <MaterialTable
                        title="Solicitudes a cargar"
                        columns={columnCarga}
                        data={dataCarga}
                        style={{marginTop: "20px"}}
                    >
                    </MaterialTable>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Button
                        style={{marginTop: "25px", float: "right"}}
                        onClick={e => {
                            handleCarga(e);
                        }}
                        color="info"
                    >
                        Cargar
                    </Button>
                </GridItem>
            </Grid> 
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={openSnackbar} onClose={SnackbarClose} autoHideDuration={6000}>
                <Alert onClose={SnackbarClose} severity={color} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            <Loader load={load} />
        </div>
    )
}