import React, { useEffect, useState, createRef, useRef } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import logo from "assets/img/logotipo.svg";

let ps;

const useStyles = makeStyles(styles);

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Admin({ ...rest }) {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = createRef();
  // states and functions
  const [mobileOpen, setMobileOpen] = useState(false);
  const [rutas, setRutas] = useState([]);
  const [inicio, setInicio] = useState("/admin/dashboard");

  const [color, setColor] = useState("");
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const mounted = useRef();

  const sendSnackBar = (pmessage, pcolor) => {
    setOpenSnackbar(true);
    setColor(pcolor);
    setMessage(pmessage);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  useEffect(() => {
    if (!mounted.current) {
        mounted.current = true;
    } else {     
        document.getElementsByTagName("header")[0].scrollIntoView({block: "start", behavior: "auto"});
    }
  });

  const switchRoutes = (
    <Switch>
      {rutas.map((prop, key) => {
        if (prop.layout === "/admin") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return null;
      })}
      <Redirect from="/" to={inicio} />
    </Switch>
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const getRol = () => {
    return JSON.parse(localStorage.getItem("userICR")).idRol;
  };

  const Login = async () => {
    let dataResponse;
    try {
        let header = {
            method: "POST",
            body: JSON.stringify({UserName: process.env.REACT_APP_USER, Password: process.env.REACT_APP_PASS}),
            headers: {
                "Content-Type": "application/json"
            }
        };

        let response = await fetch(
            process.env.REACT_APP_URL_APICORE + "Login",
            header
        );

        dataResponse = await response.json();
    } catch (error) {
        sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const ObtieneVistasPorRol = async (token, IdRol) => {
    let dataResponse;

    try {
      let header = {
        method: "POST",
        body: JSON.stringify({ IdRol }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      };

      let response = await fetch(process.env.REACT_APP_URL_APICORE + "Vistas/ObtieneVistasPorRol", header);
      dataResponse = await response.json();
    } catch (error) {
      sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  // initialize and destroy the PerfectScrollbar plugin
  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  useEffect(() => {
    let rut = [...routes];
    Login()
    .then(result => {
      if(result !== undefined){
        let idRol = getRol();

        ObtieneVistasPorRol(result.token, idRol)
        .then(result => {
          let idVistaArray = result.rolVistas.map(element => { return element.IdVista; });
          rut = rut.filter(element => [...idVistaArray, 0].includes(element.id));
          setInicio(rut[0].layout + rut[0].path);
          setRutas(rut);
        }).catch(error => sendSnackBar("Error de conexión", "error"));
      } else {
        sendSnackBar("Error de conexión", "error");
      }
    }).catch(error => sendSnackBar("Error de conexión", "error"));
  }, []);

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={rutas}
        logo={logo}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color="blue"
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={rutas}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />

          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        
          <Footer />
          
          <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={openSnackbar} onClose={handleClose} autoHideDuration={6000}>
              <Alert onClose={handleClose} severity={color} sx={{ width: '100%' }}>
                  {message}
              </Alert>
          </Snackbar>
      </div>
    </div>
  );
}
