import React, { useState, useEffect } from "react";

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from "@material-ui/core/CircularProgress";

import "assets/css/loader.css";

export default function ReporteCaptura(props) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if(props.load) {
            handleClickOpen();
        } else {
            handleClose();
        }
    }, [props.load]);

    return (
        <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogContent className="content">
                <CircularProgress className="loader"></CircularProgress>
                <label className="margen">Cargando ...</label>
            </DialogContent>
        </Dialog>
    );
}