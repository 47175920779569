import React, { useState, forwardRef, useEffect } from 'react';

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Switch from '@mui/material/Switch';

import esLocale from 'date-fns/locale/es';
import moment from "moment";
import "moment/locale/es";

import Loader from "components/Loader/Loader";

import { Redirect } from 'react-router-dom';

moment.locale("es");

const MayorDeEdad = () => {
    let dt = new Date();
    return new Date(dt.setFullYear(dt.getFullYear() - 18));        
};

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const columns = [
    { id: 'Referencia', label: 'Referencia', minWidth: 100 },
    { id: 'Nombres', label: 'Nombre', minWidth: 100 },
    { id: 'FechaNacimiento', label: 'Fecha Nacimiento', minWidth: 100 },
    { id: 'Correo', label: 'Correo', minWidth: 100 },
    { id: 'NombreCapturista', label: 'Capturista', minWidth: 100 }
];

function Validacion(){
    const [productos, setProductos] = useState([]);
    const [capturas, setCapturas] = useState([]);
    const [estatuses, setEstatuses] = useState([]);
    const [estatus, setEstatus] = useState("1");

    const [IdCaptura, setIdCaptura] = useState("");
    const [Nombres, setNombres] = useState("");
    const [ApellidoPaterno, setApellidoPaterno] = useState("");
    const [ApellidoMaterno, setApellidoMaterno] = useState("");
    const [FechaNacimiento, setFechaNacimiento] = useState(MayorDeEdad());
    const [RFC, setRFC] = useState("");
    const [Correo, setCorreo] = useState("");
    const [Celular, setCelular] = useState("");
    const [TelefonoFijo, setTelefonoFijo] = useState("");
    const [TelefonoTrabajo, setTelefonoTrabajo] = useState("");
    const [IdEstadoCivil, setIdEstadoCivil] = useState("1");
    const [IdGradoEstudios, setIdGradoEstudios] = useState("2");
    const [LugarNacimiento, setLugarNacimiento] = useState("1");

    const [Calle, setCalle] = useState("");
    const [NumeroExterior, setNumeroExterior] = useState("");
    const [NumeroInterior, setNumeroInterior] = useState("");
    const [Colonia, setColonia] = useState("");
    const [Estado, setEstado] = useState("");
    const [Localidad, setLocalidad] = useState("");
    const [CodigoPostal, setCodigoPostal] = useState("");
    const [ReferenciaTDC, setReferenciaTDC] = useState("");
    const [ReferenciaCreditoHipotecario, setReferenciaCreditoHipotecario] = useState("");
    const [ReferenciaCreditoAuto, setReferenciaCreditoAuto] = useState("");
    const [Producto, setProducto] = useState("1");
    const [Referencia, setReferencia] = useState("");
    const [Capturista, setCapturista] = useState("");
    //const [IngresoMensual, setIngresoMensual] = useState("");
    const [FechaCaptura, setFechaCaptura] = useState("");
    const [ReferenciaInvex, setReferenciaInvex] = useState("");
    const [FolioFico, setFolioFico] = useState("");
    const [TipoBase, setTipoBase] = useState("");
    const [FechaSanitizacion, setFechaSanitizacion] = useState("");
    const [Pendiente, setPendiente] = useState(false);
    const [Adicional, setAdicional] = useState(false);
    const [Asistencia, setAsistencia] = useState(false);
    const [AdicionalDisplay, setAdicionalDisplay] = useState(true);
    const [AsistenciaDisplay, setAsistenciaDisplay] = useState(true);
    const [Comentarios, setComentarios] = useState("");

    const [ActividadLaboral, setActividadLaboral] = useState("1");
    const [CodigoPostalLaboral, setCodigoPostalLaboral] = useState("");
    const [DependientesEconomicos, setDependientesEconomicos] = useState("");

    const [Colonias, setColonias] = useState([]);
    const [EstadosCiviles, setEstadosCiviles] = useState([]);
    const [GradosEstudios, setGradosEstudios] = useState([]);
    const [ActividadesLaborales, setActividadesLaborales] = useState([]);
    const [LugaresNacimiento, setLugaresNacimiento] = useState([]);

    const [FechaCapturista, setFechaCapturista] = useState("");

    const [color, setColor] = useState("");
    const [message, setMessage] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [load, setLoad] = useState(false);
    const [openDialogError, setOpenDialogError] = useState(false);

    const [tipo, setTipo] = useState('nombre');

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [ReferenciaInvexError, setReferenciaInvexError] = useState(false);
    const [FolioFicoError, setFolioFicoError] = useState(false);
    const [TipoBaseError, setTipoBaseError] = useState(false);
    const [NombresError, setNombresError] = useState(false);
    const [ApellidoPaternoError, setApellidoPaternoError] = useState(false);
    const [ApellidoMaternoError, setApellidoMaternoError] = useState(false);
    const [FechaNacimientoError, setFechaNacimientoError] = useState(false);
    const [RFCError, setRFCError] = useState(false);
    const [CelularError, setCelularError] = useState(false);
    const [TelefonoFijoError, setTelefonoFijoError] = useState(false);
    const [CalleError, setCalleError] = useState(false);
    const [NumeroExteriorError, setNumeroExteriorError] = useState(false);
    const [CodigoPostalError, setCodigoPostalError] = useState(false);
    const [ColoniaError, setColoniaError] = useState(false);
    const [EstadoError, setEstadoError] = useState(false);
    const [LocalidadError, setLocalidadError] = useState(false);
    const [ReferenciaTDCError, setReferenciaTDCError] = useState(false);
    //const [IngresoMensualError, setIngresoMensualError] = useState(false);
    const [CodigoPostalLaboralError, setCodigoPostalLaboralError] = useState(false);
    const [DependientesEconomicosError, setDependientesEconomicosError] = useState(false);

    const [CorreoError, setCorreoError] = useState(false);
    const [TextError, setTextError] = useState("");

    const [banco, setBanco] = useState("1");
    const [bancos, setBancos] = useState([]);

    const validaCampos = () => {
        let error = false;

        setReferenciaInvexError(false);
        setFolioFicoError(false);
        setTipoBaseError(false);
        setNombresError(false);
        setApellidoPaternoError(false);
        setApellidoMaternoError(false);
        setFechaNacimientoError(false);
        setRFCError(false);
        setCelularError(false);
        setTelefonoFijoError(false);
        setCalleError(false);
        setNumeroExteriorError(false);
        setCodigoPostalError(false);
        setColoniaError(false);
        setEstadoError(false);
        setLocalidadError(false);
        setReferenciaTDCError(false);
        //setIngresoMensualError(false);
        setCodigoPostalLaboralError(false);
        setDependientesEconomicosError(false);
        setCorreoError(false);

        if(ReferenciaInvex === ''){
            setReferenciaInvexError(true);
            error = true;
        }
        if(FolioFico === ''){
            setFolioFicoError(true);
            error = true;
        }
        if(TipoBase === ''){
            setTipoBaseError(true);
            error = true;
        }
        if(Nombres === ''){
            setNombresError(true);
            error = true;
        }
        if(ApellidoPaterno === ''){
            setApellidoPaternoError(true);
            error = true;
        }
        if(ApellidoMaterno === ''){
            setApellidoMaternoError(true);
            error = true;
        }
        if(!isValidDate(FechaNacimiento)){
            setFechaNacimientoError(true);
            error = true;
        }
        if(RFC === ''){
            setRFCError(true);
            error = true;
        }
        if(Correo === '' || !validateEmail(Correo)){
            setCorreoError(true);
            error = true;
        }
        if(Celular === ''){
            setCelularError(true);
            error = true;
        }
        if(TelefonoFijo === ''){
            setTelefonoFijoError(true);
            error = true;
        }
        if(Calle === ''){
            setCalleError(true);
            error = true;
        }
        if(NumeroExterior === ''){
            setNumeroExteriorError(true);
            error = true;
        }
        if(CodigoPostal === ''){
            setCodigoPostalError(true);
            error = true;
        }
        if(Colonia === ''){
            setColoniaError(true);
            error = true;
        }
        if(Estado === ''){
            setEstadoError(true);
            error = true;
        }
        if(Localidad === ''){
            setLocalidadError(true);
            error = true;
        }
        if(ReferenciaTDC === ''){
            setReferenciaTDCError(true);
            error = true;
        }
        //if(IngresoMensual === ''){
        //    setIngresoMensualError(true);
        //    error = true;
        //}
        if(CodigoPostalLaboral === ''){
            setCodigoPostalLaboralError(true);
            error = true;
        }
        if(DependientesEconomicos === ''){
            setDependientesEconomicosError(true);
            error = true;
        }

        return error;
    };

    const isValidDate = (d) => {
        return moment(d).isValid();
    }

    const getUser = () => {
        return JSON.parse(localStorage.getItem("userICR")).IdUsuario;
    };

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    const correoChange = (event) => {
        setCorreo(event.target.value);

        if(!validateEmail(event.target.value)){
            setCorreoError(true);
            setTextError("Correo invalido");
        } else {
            setCorreoError(false);
            setTextError("");
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const tipoNumero = (valor) => {
        let num = 0;

        switch(valor)
        {
            case "nombre":
                num = 1;
                break;
            case "referencia":
                num = 2;
                break;
            case "capturista":
                num = 3;
                break
            case "telefono":
                num = 4;
                break
        }

        return num;
    };

    const buscar = (event) => {
        event.preventDefault();
        setLoad(true);

        limpiaValores();

        let busqueda = document.getElementById("busqueda").value;

        if(busqueda !== ""){
            Login()
            .then(result => {
                if(result !== undefined){
                    let params = {
                        Indicador: busqueda,
                        Tipo: tipoNumero(tipo)
                    };

                    ObtieneCaptura(result.token, params)
                    .then(result => {
                        let capturas = result.capturas.map((element) => {
                            element.FechaNacimiento = element.FechaNacimiento === "" ? "" : moment(moment(element.FechaNacimiento, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                            element.FechaCaptura = element.FechaCaptura === "" ? "" : moment(moment(element.FechaCaptura, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                            element.FechaHigienizacion = element.FechaHigienizacion === "" ? "" : moment(moment(element.FechaHigienizacion, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                            element.FechaCapturista = element.FechaCapturista === "" ? "" : moment(moment(element.FechaCapturista, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                            element.FechaValidador = element.FechaValidador === "" ? "" : moment(moment(element.FechaValidador, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                            return element;
                        });
                        setCapturas(capturas);
                        setLoad(false);

                    }).catch(error => sendSnackBar("Error de conexión", "error"));
                } else {
                    sendSnackBar("Error de conexión", "error");
                }
            }).catch(error => sendSnackBar("Error de conexión", "error"));
        } else {
            sendSnackBar("Debes agregar un elemento a buscar", "error");
            setLoad(false);
        }
    };

    const sendSnackBar = (pmessage, pcolor) => {
        setOpenSnackbar(true);
        setColor(pcolor);
        setMessage(pmessage);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSnackbar(false);
    };

    const selectRow = (e, row) => {
        e.stopPropagation(); 
        e.preventDefault(); 

        let elemento = capturas.map(element => {
            if(element.IdCaptura === row.IdCaptura)
            {
                element.select = true;
                return element;
            }
            else
            {
                element.select = false;
                return element;
            }
        });

        setCapturas(elemento);
        limpiaValores();

        let cap = elemento.find(item => item.IdCaptura === row.IdCaptura);

        let now = moment(Date.now());
        now.set({h: 0, m: 0, s: 0});
        let Higienizacion = moment(cap.FechaHigienizacion, "DD-MM-YYYY").set({hour: 23, minute: 59, second: 59, millisecond: 0}).toDate();
        
        if(moment(now.toDate()).isAfter(moment(Higienizacion))){
            setOpenDialogError(true);
            setReferencia(cap.Referencia);
        } else {
            setIdCaptura(cap.IdCaptura)

            setNombres(cap.Nombres);
            setApellidoPaterno(cap.ApellidoPaterno);
            setApellidoMaterno(cap.ApellidoMaterno);
            setFechaNacimiento(moment(cap.FechaNacimiento, "DD-MM-YYYY").toDate());
            setRFC(cap.RFC);
            setCorreo(cap.Correo);
            setCelular(cap.Celular);
            setTelefonoFijo(cap.TelefonoFijo);
            setTelefonoTrabajo(cap.TelefonoTrabajo);
            setIdEstadoCivil(cap.IdEstadoCivil);
            setIdGradoEstudios(cap.IdGradoEstudios);
            setLugarNacimiento(cap.LugarNacimiento);
    
            setCalle(cap.Calle);
            setNumeroExterior(cap.NumeroExterior);
            setNumeroInterior(cap.NumeroInterior);
            setEstado(cap.Estado);
            setLocalidad(cap.Localidad);
            setCodigoPostal(cap.CodigoPostal);
    
            Login()
            .then(result => {
                if(result !== undefined){
                    let datos = { CP: cap.CodigoPostal };
    
                    ObtieneCodigosPostales(datos, result.token)
                    .then(result => {
                        if(result.codigoPostales.length > 0){
                            setColonias(result.codigoPostales);
                        }
                    }).catch(error => sendSnackBar("Error de conexión", "error"));
                } else {
                    sendSnackBar("Error de conexión", "error");
                }
            }).catch(error => sendSnackBar("Error de conexión", "error"));
    
            setBanco(cap.Banco === 0 ? '1' : cap.Banco);
            setReferenciaTDC(cap.ReferenciaTDC);
            setReferenciaCreditoHipotecario(cap.ReferenciaCreditoHipotecario);
            setReferenciaCreditoAuto(cap.ReferenciaCreditoAuto);
            setProducto(cap.Producto);
            //setIngresoMensual(cap.IngresoMensual);
            setCapturista(cap.Capturista);
            setReferencia(cap.Referencia);
            setReferenciaInvex(cap.ReferenciaInvex);
            setEstatus(cap.IdEstatus);

            if(cap.IdEstatus !== 5){
                setPendiente(false);
            } else {
                setPendiente(cap.Pendiente);
            }

            if(cap.IdEstatus  === 1 || cap.IdEstatus  === 4){
                setAdicionalDisplay(true);
                setAsistenciaDisplay(true);
                setAdicional(cap.Adicional);
                setAsistencia(cap.Asistencia);
            } else {
                setAdicionalDisplay(false);
                setAsistenciaDisplay(false);
                setAdicional(false);
                setAsistencia(false);
            }

            setFolioFico(cap.FolioFico);
            setTipoBase(cap.TipoBase);
            setFechaCaptura(cap.FechaCaptura)
            setFechaSanitizacion(cap.FechaHigienizacion);
            setComentarios(cap.Comentarios);
    
            setActividadLaboral(cap.ActividadLaboral);
            setCodigoPostalLaboral(cap.CodigoPostalLaboral);
            setDependientesEconomicos(cap.DependientesEconomicos);

            setFechaCapturista(cap.FechaCapturista);
        }
    };

    const Login = async () => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify({UserName: process.env.REACT_APP_USER, Password: process.env.REACT_APP_PASS}),
                headers: {
                    "Content-Type": "application/json"
                }
            };

            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Login",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const ActualizaCaptura = async (data, token) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };
    
            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Captura/ActualizaCaptura",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const ValidaDatos = () => {
        let datos = { IdCaptura: IdCaptura, Nombres, ApellidoPaterno, ApellidoMaterno, FechaNacimiento, RFC, Correo, Celular, TelefonoFijo, 
            TelefonoTrabajo, IdEstadoCivil, IdGradoEstudios, Calle, NumeroExterior, NumeroInterior, Colonia, Estado, Localidad, CodigoPostal, 
            ReferenciaTDC, ReferenciaCreditoHipotecario, ReferenciaCreditoAuto, Producto: parseInt(Producto), Validador: getUser(), 
            IngresoMensual: 0, ReferenciaInvex: ReferenciaInvex, IdEstatus: estatus, FolioFico, TipoBase,
            Pendiente, Comentarios, Capturista, ActividadLaboral: parseInt(ActividadLaboral), CodigoPostalLaboral, DependientesEconomicos,
            LugarNacimiento: parseInt(LugarNacimiento), FechaCapturista: FechaCapturista === "" ? "" : moment(FechaCapturista, "DD-MM-YYYY").toDate(),
            FechaValidador: moment(Date.now()).toDate(), Banco: parseInt(banco), Adicional, Asistencia };

        if(Pendiente){
            return datos;
        } else {
            if(!validaCampos()){
                return datos;
            } else {
                return { error: true }
            }
        }
    }

    const ObtieneProductos = async (token) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",

                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };
    
            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Productos/ObtieneProductos",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const ObtieneCaptura = async (token, data) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    
                    Authorization: "Bearer " + token
                }
            };
    
            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Captura/ObtieneCaptura",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const ObtieneEstatus = async (token) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",       
                    Authorization: "Bearer " + token
                }
            };
    
            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Estatus/ObtieneEstatus",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const InsertaCapturaProductos = async (data, token) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };
    
            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Captura/InsertaCapturaProductos",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const EnviaDatos = () => {
        try{
            setLoad(true);

            let datos = ValidaDatos();
            if(datos.error === undefined){
                Login()
                .then(result => {
                    if(result !== undefined){
                        ActualizaCaptura(datos, result.token)
                        .then(result => {
                            if(result.success){
                                sendSnackBar("Registro exitoso", "success");
                                setTimeout(function(){ window.location.reload(false); }, 2000);
                            } else {
                                sendSnackBar("Error de conexión", "error");
                            }
            
                            setLoad(false);
                        }).catch(error => sendSnackBar("Error de conexión", "error"));

                        let datosProductos = { Producto: parseInt(Producto), Usuario: getUser(), Captura: parseInt(IdCaptura),
                            Fecha: moment(Date.now()).toDate() };

                        InsertaCapturaProductos(datosProductos, result.token)
                        .then(result => {
                            if(result.success){
                                
                            }
                        }).catch(error => sendSnackBar("Error de conexión", "error"));
                    } else {
                        sendSnackBar("Error de conexión", "error");
                        setLoad(false);
                    }
                }).catch(error => sendSnackBar("Error de conexión", "error"));
            } else {
                sendSnackBar("Todos los valores son obligatorios", "error");
                setLoad(false);
            }
        } catch(error){
            sendSnackBar("Error de conexión", "error");
            setLoad(false);
        }
    };

    const ObtieneCodigosPostales = async (data, token) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };
    
            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "CodigoPostal/ObtieneCodigosPostales",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const ObtieneGradoEstudios = async (token) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };
    
            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "GradoEstudios/ObtieneGradoEstudios",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const ObtieneEstadoCivil = async (token) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };
    
            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "EstadoCivil/ObtieneEstadoCivil",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const ObtieneActividadesLaborales = async (token) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };
    
            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "ActividadLaboral/ObtieneActividadesLaborales",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const ObtieneEstados = async (token) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };
    
            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Estados/ObtieneEstados",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const ObtieneBancos = async (token) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };
    
            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Bancos/ObtieneBancos",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const limpiaValores = () => {
        setIdCaptura("")

        setNombres("");
        setApellidoPaterno("");
        setApellidoMaterno("");
        setFechaNacimiento(MayorDeEdad());
        setRFC("");
        setCorreo("");
        setCelular("");
        setTelefonoFijo("");
        setTelefonoTrabajo("");
        setIdEstadoCivil("1");
        setIdGradoEstudios("2");
        setLugarNacimiento("1");

        setCalle("");
        setNumeroExterior("");
        setNumeroInterior("");
        setEstado("");
        setLocalidad("");
        setCodigoPostal("");
        setColonia("");
        setColonias([]);
        setBanco("1");
        setReferenciaTDC("");
        setReferenciaCreditoHipotecario(false);
        setReferenciaCreditoAuto(false);
        setProducto("1");
        //setIngresoMensual("");
        setCapturista("");
        setReferencia("");
        setReferenciaInvex("");
        setEstatus("1");
        setFolioFico("");
        setTipoBase("");
        setFechaCaptura("")
        setFechaSanitizacion("");
        setPendiente(false);
        setAdicionalDisplay(false);
        setAsistenciaDisplay(false);
        setAdicional(false);
        setAsistencia(false);
        setComentarios("");

        setActividadLaboral("1");
        setCodigoPostalLaboral("");
        setDependientesEconomicos("");

        setFechaCapturista("");
    };

    const cpChange = (event) => {
        let nuevoCP = event.target.value;
        setCodigoPostal(nuevoCP);
        setColonias([]);
        setEstado("");
        setLocalidad("");

        if(nuevoCP.length === 5){
            Login()
            .then(result => {
                if(result !== undefined){
                    let datos = { CP: nuevoCP };

                    ObtieneCodigosPostales(datos, result.token)
                    .then(result => {
                        if(result.codigoPostales.length > 0){
                            setColonias(result.codigoPostales);
                            setEstado(result.codigoPostales[0].Estado);
                            setLocalidad(result.codigoPostales[0].Municipio);
                        }
                    }).catch(error => sendSnackBar("Error de conexión", "error"));
                } else {
                    sendSnackBar("Error de conexión", "error");
                }
            }).catch(error => sendSnackBar("Error de conexión", "error"));
        }
    };

    const estatusChange = (event) => {
        if(event.target.value !== 5){
            setPendiente(false);
        } else {
            setPendiente(true);
        }

        if(event.target.value === 1 || event.target.value === 4){
            setAdicionalDisplay(true);
            setAsistenciaDisplay(true);
        } else {
            setAdicionalDisplay(false);
            setAsistenciaDisplay(false);
            setAdicional(false);
            setAsistencia(false);
        }

        setEstatus(event.target.value);
    };

    const GetIdUsuario = () => {
        return JSON.parse(localStorage.getItem('userICR')).IdUsuario;
      };
    
      const UsuarioActivo = async (data, token) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };
    
            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Usuario/UsuarioActivo",
                header
            );
    
            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
      };

    useEffect(() => {
        Login()
        .then(result => {
        let token = result.token;

        if(result !== undefined) {
            UsuarioActivo({ IdUsuario: GetIdUsuario() },token)
            .then(result => {
            console.log(result);
            if(result.activo === false){
                localStorage.removeItem("userICR");
                return <Redirect to='/admin'/>;
            }
            });
        } else {
            sendSnackBar("Error de conexión", "error");
        }
        });

        Login()
        .then(result => {
            if(result !== undefined){
                ObtieneProductos(result.token)
                .then(result => {
                    setProductos(result.productos);
                }).catch(error => sendSnackBar("Error de conexión", "error"));

                ObtieneEstatus(result.token)
                .then(result => {
                    setEstatuses(result.estatus);
                }).catch(error => sendSnackBar("Error de conexión", "error"));

                ObtieneGradoEstudios(result.token)
                .then(result => {
                    setGradosEstudios(result.gradoEstudios);
                }).catch(error => sendSnackBar("Error de conexión", "error"));

                ObtieneEstadoCivil(result.token)
                .then(result => {
                    setEstadosCiviles(result.estadoCiviles);
                }).catch(error => sendSnackBar("Error de conexión", "error"));

                ObtieneActividadesLaborales(result.token)
                .then(result => {
                    setActividadesLaborales(result.actividadesLaborales);
                }).catch(error => sendSnackBar("Error de conexión", "error"));

                ObtieneEstados(result.token)
                .then(result => {
                    setLugaresNacimiento(result.estados);
                }).catch(error => sendSnackBar("Error de conexión", "error"));

                ObtieneBancos(result.token)
                .then(result => {
                    setBancos(result.bancos);
                }).catch(error => sendSnackBar("Error de conexión", "error"));
            } else {
                sendSnackBar("Error de conexión", "error");
            }
        }).catch(error => sendSnackBar("Error de conexión", "error"));
    }, []);

    useEffect(() => {
        if(Colonias.length > 0 && Colonia === ""){
            setColonia(Colonias[0].Colonia);
            if(IdCaptura !== ""){
                let elemento = capturas.filter(element => element.IdCaptura === IdCaptura);

                if(CodigoPostal === elemento[0].CodigoPostal){
                    setColonia(elemento[0].Colonia);
                }
            }
        }
        if(Colonias.length === 0 && Colonia !== ""){
            setColonia("");
        }
    });

    return (
        <>  
            <Grid container>
                <Grid item xs={12} sm={6} xl={6}>
                    <Paper
                        style={{ marginBottom: "30px" }}
                        component="form"
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                        >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Buscar..."
                            inputProps={{ 'aria-label': 'search google maps' }}
                            id="busqueda"
                        />
                        <IconButton onClick={buscar} sx={{ p: '10px' }} aria-label="search">
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} xl={6}>
                    <FormControl component="fieldset">
                        <RadioGroup row aria-label="gender" name="row-radio-buttons-group">
                            <FormControlLabel
                                checked={tipo === 'nombre'}
                                onChange={(event) => setTipo(event.target.value)}
                                value="nombre"  
                                control={<Radio />} 
                                label="Nombre cliente" />
                            <FormControlLabel
                                checked={tipo === 'referencia'}
                                onChange={(event) => setTipo(event.target.value)}
                                value="referencia" 
                                control={<Radio />} 
                                label="Referencia" />
                            <FormControlLabel
                                checked={tipo === 'capturista'}
                                onChange={(event) => setTipo(event.target.value)}
                                value="capturista" 
                                control={<Radio />} 
                                label="Capturista" />
                            <FormControlLabel
                                checked={tipo === 'telefono'}
                                onChange={(event) => setTipo(event.target.value)}
                                value="telefono" 
                                control={<Radio />} 
                                label="Teléfono" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                { capturas.length > 0 && 
                    (
                        <Grid item xs={12} sm={12} xl={12} style={{ marginBottom: "30px" }}>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: 440 }}>
                                    <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {capturas.map((row) => {
                                            return (
                                                <TableRow onClick={(e) => selectRow(e, row)} selected={row.select} hover={true} tabIndex={-1} key={capturas.Referencia}>
                                                    {columns.map((column) => {
                                                        return (
                                                            <TableCell key={column.id} align={column.align}>
                                                                { column.id === "Nombres" ? 
                                                                    row["Nombres"] + " " + row["ApellidoPaterno"] + " " + row["ApellidoMaterno"]
                                                                    : 
                                                                    row[column.id]
                                                                }
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={capturas.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={(event, newPage) => setPage(newPage)}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </Grid>
                    )
                }
            </Grid>
            <Card style={{ marginBottom: "30px" }}>
                <Grid container spacing={3} style={{ padding: "30px" }}>
                    <Grid item xs={12} sm={12} xl={12}>
                        <Typography variant="h6">
                            Indices
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField value={Referencia} disabled={true} label="Referencia" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 45 }} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField onChange={(event) => setReferenciaInvex(event.target.value)} value={ReferenciaInvex} label="Folio inteligente*" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 100 }} error={ReferenciaInvexError} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField onChange={(event) => setFolioFico(event.target.value)} value={FolioFico} label="Folio fico*" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 100 }} error={FolioFicoError} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField onChange={(event) => setTipoBase(event.target.value)} value={TipoBase} label="Tipo de base*" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 100 }} error={TipoBaseError} />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField value={FechaCaptura} disabled={true} label="Fecha de alta" variant="outlined" style={{ width: "100%" }} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField value={FechaSanitizacion} disabled={true} label="Fecha sanitización" variant="outlined" style={{ width: "100%" }} /> 
                    </Grid>
                </Grid>
            </Card>
            <Card style={{ marginBottom: "30px" }}>
                <Grid container spacing={3} style={{ padding: "30px" }}>
                    <Grid item xs={12} sm={12} xl={12}>
                        <Typography variant="h6">
                            Pendientes
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField onChange={(event) => setComentarios(event.target.value)} value={Comentarios} label="Comentarios" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 500 }} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField
                            select
                            label="Estatus*"
                            value={estatus} 
                            onChange={event => estatusChange(event)}
                            style={{ width: "100%" }}
                        >
                            {estatuses.map((option) => (
                                <MenuItem key={option.IdEstatus} value={option.IdEstatus}>
                                    {option.Nombre}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={Pendiente} />} label="Pendiente" style={{ color: "black" }} />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4} style={{display: AdicionalDisplay ? 'block' : 'none'}}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={Adicional} onChange={(event => {setAdicional(event.target.checked)})} />} label="Adicional" style={{ color: "black" }} />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4} style={{display: AsistenciaDisplay ? 'block' : 'none'}}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={Asistencia} onChange={(event => {setAsistencia(event.target.checked)})}  />} label="Asistencia" style={{ color: "black" }} />
                        </FormGroup>
                    </Grid>
                </Grid>
            </Card>
            <Card style={{ marginBottom: "30px" }}>
                <Grid container spacing={3} style={{ padding: "30px" }}>
                    <Grid item xs={12} sm={12} xl={12}>
                        <Typography variant="h6">
                            Datos personales
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField onChange={(event) => setNombres(event.target.value)} value={Nombres} label="Nombres*" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 100 }} error={NombresError} />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField onChange={(event) => setApellidoPaterno(event.target.value)} value={ApellidoPaterno} label="Apellido Paterno*" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 100 }} error={ApellidoPaternoError} />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField onChange={(event) => setApellidoMaterno(event.target.value)} value={ApellidoMaterno} label="Apellido Materno*" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 100 }} error={ApellidoMaternoError} />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                            <MobileDatePicker
                                label="Fecha de nacimiento*"
                                cancelText="Cancelar"
                                value={FechaNacimiento}
                                maxDate={MayorDeEdad()}
                                onChange={(newValue) => {
                                    setFechaNacimiento(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} style={{ width: "100%" }} error={FechaNacimientoError} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField onChange={(event) => setRFC(event.target.value)} value={RFC} label="RFC*" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 13 }} error={RFCError} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField onChange={(event) => correoChange(event)} value={Correo} label="Correo*" type="email" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 100 }} error={CorreoError} helperText={TextError} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField onChange={(event) => setCelular(event.target.value)} value={Celular} label="Celular*" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 10 }} error={CelularError} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField onChange={(event) => setTelefonoFijo(event.target.value)} value={TelefonoFijo} label="Teléfono fijo*" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 10 }} error={TelefonoFijoError} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField onChange={(event) => setTelefonoTrabajo(event.target.value)} value={TelefonoTrabajo} label="Teléfono de trabajo" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 10 }} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField
                            select
                            label="Estado civil*"
                            value={IdEstadoCivil} 
                            onChange={(event) => setIdEstadoCivil(event.target.value)} 
                            style={{ width: "100%" }}
                        >
                            {EstadosCiviles.map((option) => (
                                <MenuItem key={option.Id} value={option.Id}>
                                    {option.Nombre}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField
                            select
                            label="Grado de estudios*"
                            value={IdGradoEstudios} 
                            onChange={(event) => setIdGradoEstudios(event.target.value)} 
                            style={{ width: "100%" }}
                        >
                            {GradosEstudios.map((option) => (
                                <MenuItem key={option.Id} value={option.Id}>
                                    {option.Nombre}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField
                            select
                            label="Lugar de nacimiento*"
                            value={LugarNacimiento} 
                            onChange={(event) => setLugarNacimiento(event.target.value)} 
                            style={{ width: "100%" }}
                        >
                            {LugaresNacimiento.map((option) => (
                                <MenuItem key={option.IdEstados} value={option.IdEstados}>
                                    {option.Nombre}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            </Card>
            <Card style={{ marginBottom: "30px" }}>
                <Grid container spacing={3} style={{ padding: "30px" }}>
                    <Grid item xs={12} sm={12} xl={12}>
                        <Typography variant="h6">
                            Dirección
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField onChange={(event) => setCalle(event.target.value)} value={Calle} label="Calle*" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 45 }} error={CalleError} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField onChange={(event) => setNumeroExterior(event.target.value)} value={NumeroExterior} label="Número exterior*" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 45 }} error={NumeroExteriorError} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField onChange={(event) => setNumeroInterior(event.target.value)} value={NumeroInterior} label="Número interior" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 45 }} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField id="codigoPostal" onChange={cpChange} value={CodigoPostal} label="Código postal*" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 5 }} error={CodigoPostalError} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField
                            select
                            label="Colonia*"
                            value={Colonia} 
                            onChange={(event) => setColonia(event.target.value)} 
                            style={{ width: "100%" }}
                            error={ColoniaError}
                        >
                            {Colonias.map((option) => (
                                <MenuItem key={option.Colonias} value={option.Colonia}>
                                    {option.Colonia}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField onChange={(event) => setEstado(event.target.value)} value={Estado} label="Estado*" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 45 }} error={EstadoError} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField onChange={(event) => setLocalidad(event.target.value)} value={Localidad} label="Municipio/Alcaldia*" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 45 }} error={LocalidadError} /> 
                    </Grid>
                </Grid>
            </Card>
            <Card style={{ marginBottom: "30px" }}>
                <Grid container spacing={3} style={{ padding: "30px" }}>
                    <Grid item xs={12} sm={12} xl={12}>
                        <Typography variant="h6">
                            Referencias
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField
                            select
                            label="Bancos*"
                            value={banco} 
                            onChange={(event) => setBanco(event.target.value)} 
                            style={{ width: "100%" }}
                        >
                            {bancos.map((option) => (
                                <MenuItem key={option.IdBancos} value={option.IdBancos}>
                                    {option.Nombre}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField onChange={(event) => setReferenciaTDC(event.target.value)} value={ReferenciaTDC} label="Referencia TDC*" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 45 }} error={ReferenciaTDCError} /> 
                    </Grid>
                    {/* <Grid item xs={12} sm={6} xl={4}>
                        <TextField onChange={(event) => setIngresoMensual(event.target.value)} value={IngresoMensual} label="Ingreso mensual*" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 11, type: "number" }} error={IngresoMensualError} /> 
                    </Grid> */}
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField
                            select
                            label="Productos*"
                            value={Producto} 
                            onChange={(event) => setProducto(event.target.value)} 
                            style={{ width: "100%" }}
                        >
                            {productos.map((option) => (
                                <MenuItem key={option.IdProductos} value={option.IdProductos}>
                                    {option.Nombre}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <FormGroup>
                            <FormControlLabel control={<Switch checked={ReferenciaCreditoHipotecario} onChange={(event) => setReferenciaCreditoHipotecario(event.target.checked)} />} label={"¿Crédito hipotecario?"} />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <FormGroup>
                            <FormControlLabel control={<Switch checked={ReferenciaCreditoAuto} onChange={(event) => setReferenciaCreditoAuto(event.target.checked)} />} label={"¿Crédito auto?"} />
                        </FormGroup>
                    </Grid>
                </Grid>
            </Card>
            <Card style={{ marginBottom: "30px" }}>
                <Grid container spacing={3} style={{ padding: "30px" }}>
                    <Grid item xs={12} sm={12} xl={12}>
                        <Typography variant="h6">
                            Datos Laborales
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField
                            select
                            label="Actividad laboral*"
                            value={ActividadLaboral} 
                            onChange={(event) => setActividadLaboral(event.target.value)} 
                            style={{ width: "100%" }}
                        >
                            {ActividadesLaborales.map((option) => (
                                <MenuItem key={option.IdActividadLaboral} value={option.IdActividadLaboral}>
                                    {option.Nombre}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField onChange={(event) => setCodigoPostalLaboral(event.target.value)} value={CodigoPostalLaboral} label="Código postal*" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 5 }} error={CodigoPostalLaboralError} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField onChange={(event) => setDependientesEconomicos(event.target.value)} value={DependientesEconomicos} label="Dependientes economicos*" variant="outlined" style={{ width: "100%" }} inputProps={{ maxlength: 100 }} error={DependientesEconomicosError} /> 
                    </Grid>
                </Grid>
            </Card>
            <Grid container
                direction="row-reverse"
                justifyContent="flex-start"
                alignItems="center">
                <Grid item xs={12} sm={12} xl={3}>
                    <Button
                        color="info"
                        variant="contained"
                        onClick={EnviaDatos}
                        style={{ float: "right" }}
                        size="large"
                        >
                            Enviar
                    </Button>
                </Grid>
            </Grid>
            <Dialog
                open={openDialogError}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setOpenDialogError(false)}
                aria-describedby="AlertReferencia"
            >
                <DialogTitle>{"Fecha de Higienizacion fuera de rango"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="AlertReferencia">
                        {`Referencia: ${Referencia}`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenDialogError(false); setReferencia(""); }}>Aceptar</Button>
                </DialogActions>
            </Dialog>
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={openSnackbar} onClose={handleClose} autoHideDuration={6000}>
                <Alert onClose={handleClose} severity={color} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            <Loader load={load} />
        </>
    );
}

export default Validacion;